import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { sdk } from '@/api.ts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

interface ModelOption {
  llm_provider: string;
  llm_model: string;
}

interface ICurrentPrompt {
  system_prompt?: string;
  llm_provider?: string;
  llm_model?: string;
}

const Prompt = ({ organizationId }: { organizationId: string }) => {
  const [currentPrompt, setCurrentPrompt] = useState<ICurrentPrompt>({});
  const [modelOptions, setModelOptions] = useState<ModelOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const params = useParams();

  const FormSchema = z.object({
    systemPrompt: z.string().min(1, {
      message: 'Prompt must not be empty.',
    }),
    llmProvider: z.string(),
    llmModel: z.string(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      systemPrompt: currentPrompt.system_prompt ?? '',
      llmProvider: currentPrompt.llm_provider ?? '',
      llmModel: currentPrompt.llm_model ?? '',
    },
  });

  if (!organizationId) {
    organizationId = params.id!;
  }
  useEffect(() => {
    fetchPrompt();
    fetchOptions();
  }, []);

  useEffect(() => {
    if (success) {
      window.setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    if (currentPrompt && modelOptions.length > 0) {
      form.setValue('systemPrompt', currentPrompt.system_prompt ?? '');
      form.setValue('llmProvider', currentPrompt.llm_provider ?? '');

      // Check if the model exists for the provider
      const modelExists = modelOptions.some(
        (option) =>
          option.llm_provider === currentPrompt.llm_provider &&
          option.llm_model === currentPrompt.llm_model
      );

      if (modelExists) {
        form.setValue('llmModel', currentPrompt.llm_model ?? '');
      } else {
        // Set first available model for this provider
        const firstModel = modelOptions.find(
          (opt) => opt.llm_provider === currentPrompt.llm_provider
        )?.llm_model;

        if (firstModel) {
          form.setValue('llmModel', firstModel);
        }
      }
    }
  }, [currentPrompt, modelOptions, form]);

  const fetchPrompt = async () => {
    try {
      const result = await sdk.getPrompt(organizationId);

      setCurrentPrompt(result);
    } catch (error) {
      // @TODO: handle error
    }
  };

  const fetchOptions = async () => {
    try {
      const result = await sdk.getPromptModelOptions();
      const options: ModelOption[] = result.available_llm_options;

      setModelOptions(options);
    } catch (error) {
      // @TODO: handle error
    }
  };

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setLoading(true);
    try {
      await sdk.updatePrompt(organizationId, data);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      //@TODO: handle error
      console.error('error: ', error);
      setLoading(false);
    }
  };

  const handleProviderChange = (provider: string) => {
    // Update provider field
    form.setValue('llmProvider', provider);

    // Find and set first available model for this provider
    const firstModelForProvider = modelOptions.find(
      (opt) => opt.llm_provider === provider
    )?.llm_model;

    if (firstModelForProvider) {
      form.setValue('llmModel', firstModelForProvider);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
        <div className="flex gap-x-4">
          <FormField
            name="llmProvider"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>LLM Provider</FormLabel>
                <Select
                  onValueChange={handleProviderChange}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger test-id="llm-provider">
                      <SelectValue placeholder="LLM Provider" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {[
                      ...new Set(modelOptions.map((opt) => opt.llm_provider)),
                    ].map((provider) => (
                      <SelectItem key={provider} value={provider}>
                        {provider}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="llmModel"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>LLM Model</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger test-id="llm-model">
                      <SelectValue placeholder="LLM Model" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {modelOptions
                      ?.filter(
                        (option) =>
                          option.llm_provider === form.getValues('llmProvider')
                      )
                      .map((option) => (
                        <SelectItem
                          key={option.llm_model}
                          value={option.llm_model}
                        >
                          {option.llm_model}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="systemPrompt"
          render={({ field }) => (
            <FormItem>
              <FormLabel>System Prompt</FormLabel>
              <FormControl>
                <Textarea className="min-h-[400px]" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button disabled={loading} loading={loading} type="submit">
            Submit
          </Button>
          {success ? (
            <p className="text-success">Prompt updated successfully</p>
          ) : null}
        </div>
      </form>
    </Form>
  );
};

export default Prompt;
