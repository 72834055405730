import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { useEffect } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { IFinchConnectionOrEmpty, IFinchProvider } from '@/types.ts';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { sdk } from '@/api.ts';
import useFinchStore from '@/routes/app/organizations/sponsors/single/finch/store.ts';

interface ProvidersFormProps {
  connection: IFinchConnectionOrEmpty;
  loading: boolean;
  organizationID: string;
  providers: IFinchProvider[];
  successCallback: () => void;
}

type FormData = z.infer<typeof formSchema>;

const formSchema = z.object({
  finch_provider_id: z.string().optional(),
  status_active: z.boolean().optional(),
  cron_active: z.boolean().optional(),
});

const ProvidersForm = ({
  connection,
  loading,
  organizationID,
  providers,
  successCallback,
}: ProvidersFormProps) => {
  const { setLoading } = useFinchStore();

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      finch_provider_id: connection.finch_provider_id || '',
      status_active: connection.status_active || false,
      cron_active: connection.cron_active || false,
    },
  });

  const isAlreadyConnected = !!connection.finch_provider_id;

  useEffect(() => {
    form.setValue('finch_provider_id', connection.finch_provider_id || '');
    form.setValue('status_active', connection.status_active || false);
    form.setValue('cron_active', connection.cron_active || false);
  }, [connection]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      // If there's already a connection, we need to PATCH to the configure endpoint
      if (!isAlreadyConnected) {
        await sdk.createFinchConfiguration(organizationID, data);
      } else {
        await sdk.updateFinchConfiguration(organizationID, data);
      }
      setLoading(false);
      successCallback();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-4"
      >
        {!isAlreadyConnected ? (
          <FormField
            name="finch_provider_id"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl className="">
                    <SelectTrigger test-id="finch-provider-dropdown">
                      <SelectValue placeholder="Select a Provider" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {providers.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.display_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
        <FormField
          control={form.control}
          name="status_active"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Is Active</FormLabel>
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cron_active"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Nightly Cron</FormLabel>
              </div>
            </FormItem>
          )}
        />
        <div>
          <Button
            disabled={!form.formState.isDirty}
            loading={loading}
            type={'submit'}
          >
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ProvidersForm;
