import { useEffect } from 'react';
import ProvidersForm from '@/routes/app/organizations/sponsors/single/finch/providers-form.tsx';
import { IOrganization } from '@/types.ts';
import { Card } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import FinchConnectionData from '@/routes/app/organizations/sponsors/single/finch/finch-connection-data.tsx';
import FinchImportErrors from '@/routes/app/organizations/sponsors/single/finch/finch-import-errors.tsx';
import useFinchStore from '@/routes/app/organizations/sponsors/single/finch/store.ts';
import { sdk } from '@/api.ts';
import { BiPlug, BiPowerOff } from 'react-icons/bi';

interface FinchProps {
  organization: IOrganization;
}

const Finch = ({ organization }: FinchProps) => {
  const {
    init,
    connection,
    employees,
    loading,
    disableModalOpen,
    disconnectModalOpen,
    providers,
    setDisconnectModalOpen,
    setDisableModalOpen,
    status,
  } = useFinchStore();

  useEffect(() => {
    init(organization.id);
  }, []);

  return (
    <div>
      <Dialog open={disableModalOpen} onOpenChange={setDisableModalOpen}>
        <DialogContent>
          <h2 className="text-lg font-semibold mb-2">Disable Integration</h2>
          <p>Are you sure you want to disable the integration with Finch?</p>
          <p>This connection can be re-activated.</p>
          <div className="flex justify-end mt-4">
            <Button
              variant="destructive"
              onClick={async () => {
                try {
                  await sdk.disableFinchConfiguration(organization.id);
                  init(organization.id);
                  setDisableModalOpen(false);
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              Disable Integration
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={disconnectModalOpen} onOpenChange={setDisconnectModalOpen}>
        <DialogContent>
          <h2 className="text-lg font-semibold mb-2">Disconnect Integration</h2>
          <p>Are you sure you want to disconnect the integration with Finch?</p>
          <div className="flex justify-end mt-4">
            <Button
              variant="destructive"
              onClick={async () => {
                try {
                  await sdk.disconnectFinchConfiguration(organization.id);
                  init(organization.id);
                  setDisconnectModalOpen(false);
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              Disconnect Integration
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Card className="flex justify-between my-4">
        <div className="flex items-center gap-x-4">
          <h2 className="text-lg font-semibold">Finch Connection Status</h2>
          <Badge>{status}</Badge>
        </div>

        <div className="flex flex-col gap-y-2 justify-center">
          {connection.connection_id && connection.status_active ? (
            <Button
              variant="outline"
              className="flex items-center gap-x-2"
              onClick={() => setDisableModalOpen(true)}
            >
              <BiPowerOff />
              <span>Disable Integration</span>
            </Button>
          ) : null}
          {connection.connection_id ? (
            <Button
              variant="outline-destructive"
              className="flex items-center gap-x-2"
              onClick={() => setDisconnectModalOpen(true)}
            >
              <BiPlug />
              <span>Disconnect Integration</span>
            </Button>
          ) : null}
        </div>
      </Card>

      <Card>
        <div className="flex w-full">
          <div className="w-1/2">
            <h2 className="text-lg font-semibold mb-4">Finch Provider</h2>
            <ProvidersForm
              connection={connection}
              loading={loading}
              organizationID={organization.id}
              providers={providers}
              successCallback={() => init(organization.id)}
            />
          </div>
        </div>
      </Card>

      <Card className="my-4">
        <FinchConnectionData connection={connection} />
      </Card>

      <Card>
        <FinchImportErrors employees={employees} />
      </Card>
    </div>
  );
};

export default Finch;
