import { IEmployee } from '@/types.ts';

export const filterEmployeesWithErrors = (employees: IEmployee[]) => {
  return employees.filter((employee) => {
    return employee.possible_errors?.length;
  });
};

// converts camel and snake_case to title case
// ex: created_at => Created At
// ex: createdAt => Created At
export const toTitleCase = (str: string) => {
  if (str.includes('_')) {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const result = str.replace(/([A-Z])/g, ' $1').replace(/^\s/, '');

  return result.charAt(0).toUpperCase() + result.slice(1);
};
