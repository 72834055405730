import { IEmployee } from '@/types.ts';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import {
  filterEmployeesWithErrors,
  toTitleCase,
} from '@/routes/app/organizations/sponsors/single/finch/utils.ts';
import { Badge } from '@/components/ui/badge.tsx';

interface FinchImportErrorsProps {
  employees: IEmployee[];
}

const FinchAccordionTrigger = ({ employee }: { employee: IEmployee }) => {
  return (
    <div className="flex gap-x-2">
      <span>
        {employee.first_name} {employee.last_name}
      </span>
      <Badge variant="pending">
        {employee.possible_errors?.length} issue
        {employee.possible_errors?.length === 1 ? '' : 's'}
      </Badge>
    </div>
  );
};

const FinchImportErrors = ({ employees }: FinchImportErrorsProps) => {
  const filtered = filterEmployeesWithErrors(employees);

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Finch Import Errors</h2>
      {!filtered.length ? (
        <p>No import errors.</p>
      ) : (
        <Accordion type="single" collapsible className="w-full">
          {filtered.map((employee: IEmployee) => {
            return (
              <AccordionItem key={employee.id} value={employee.id}>
                <AccordionTrigger className="hover:no-underline">
                  <FinchAccordionTrigger employee={employee} />
                </AccordionTrigger>
                <AccordionContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Issue Type</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead>Suggested Fix</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {employee.possible_errors?.map((error) => {
                        return (
                          <TableRow key={error.error_description}>
                            <TableCell>
                              {toTitleCase(error.field_name)}
                            </TableCell>
                            <TableCell>{error.error_description}</TableCell>
                            <TableCell>{error.suggested_fix}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};

export default FinchImportErrors;
