import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import { BiChevronRight } from 'react-icons/bi';

const buttonVariants = cva(
  'inline-flex items-center justify-center font-medium rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:opacity-90 disabled:bg-neutral-200 disabled:text-foreground',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-primary bg-background hover:bg-accent hover:text-accent-foreground disabled:opacity-50',
        'outline-destructive':
          'border border-destructive bg-background text-destructive hover:bg-destructive hover:text-destructive-foreground  disabled:opacity-50',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        'link-destructive':
          'text-destructive underline-offset-4 hover:underline',
        chat: 'font-bold underline hover:underline text-md',
        prompt:
          'bg-white rounded-full border border-input hover:bg-accent px-0',
        cta: 'rounded-full px-3 flex justify-between items-center px-3 bg-primary text-primary-foreground hover:opacity-90 disabled:bg-neutral-200 disabled:text-foreground',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-6 rounded-md px-4 text-xs',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        empty: ' px-0 py-0',
        cta: 'h-10 px-3 py-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, loading, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    if (variant === 'cta') {
      size = 'cta';
      props.children = <BiChevronRight size={16} />;
    }

    if (loading) {
      props.children = <LoadingSpinner size={16} />;
    }

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
