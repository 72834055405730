import { create } from 'zustand';
import { sdk } from '@/api.ts';
import { IFinchConnectionOrEmpty, IEmployee, IFinchProvider } from '@/types.ts';

interface IFinchStore {
  connection: IFinchConnectionOrEmpty;
  employees: IEmployee[];
  loading: boolean;
  disableModalOpen: boolean;
  disconnectModalOpen: boolean;
  providers: IFinchProvider[];
  status: string;
  init: (orgId: string) => void;
  setDisconnectModalOpen: (open: boolean) => void;
  setDisableModalOpen: (open: boolean) => void;
  setLoading: (loading: boolean) => void;
}

const useFinchStore = create<IFinchStore>((set) => ({
  connection: {},
  employees: [],
  loading: false,
  disableModalOpen: false,
  disconnectModalOpen: false,
  providers: [],
  status: '',
  init: async (orgId: string) => {
    set({ loading: true });
    try {
      const providersResult = await sdk.getFinchProviders();
      const connectionResult = await sdk.getFinchConnection(orgId);
      const employeesResult = await sdk.getFinchEmployees(orgId);
      const statusResult = await sdk.getFinchStatus(orgId);

      const storeDataFromResponse = {
        connection: connectionResult,
        employees: employeesResult.employees,
        providers: providersResult,
        status: statusResult.status,
        loading: false,
      };
      set(storeDataFromResponse);
    } catch (error) {
      set({ loading: false });
      console.error(error);
    }
  },
  setDisableModalOpen: (open: boolean) => set({ disableModalOpen: open }),
  setDisconnectModalOpen: (open: boolean) => set({ disconnectModalOpen: open }),
  setLoading: (l: boolean) => set({ loading: l }),
}));

export default useFinchStore;
