import { createBrowserRouter, Navigate } from 'react-router';
import Login from '@/routes/login';
import ProtectedRoute from '@/components/protected-route.tsx';
import AppLayout from '@/routes/app/layout.tsx';
import App from '@/routes/app';
import Users from '@/routes/app/users';
import User from '@/routes/app/users/single';
import Organizations from '@/routes/app/organizations';
import Sponsors from '@/routes/app/organizations/sponsors';
import Sponsor from '@/routes/app/organizations/sponsors/single';
import Advisors from '@/routes/app/organizations/advisors';
import Advisor from '@/routes/app/organizations/advisors/single';
import MarketplaceProviders from '@/routes/app/organizations/marketplace';
import MarketplaceProvider from '@/routes/app/organizations/marketplace/single';
import OrganizationUsers from '@/routes/app/organizations/shared/users';
import ChatHistory from '@/routes/app/chat-history';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/app',
    element: <ProtectedRoute element={<AppLayout />} />,
    children: [
      {
        path: '',
        element: <App />,
      },
      {
        path: 'chat-history/:user_id',
        element: <ChatHistory />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:id',
        element: <User />,
      },
      {
        path: 'organizations',
        element: <Organizations />,
      },
      {
        path: 'organizations/plan_sponsor',
        element: <Sponsors />,
      },
      {
        path: 'organizations/plan_sponsor/:id',
        element: <Sponsor />,
      },
      {
        path: 'organizations/plan_sponsor/:id/users',
        element: <OrganizationUsers />,
      },
      {
        path: 'organizations/distribution_partner',
        element: <Advisors />,
      },
      {
        path: 'organizations/distribution_partner/:id',
        element: <Advisor />,
      },
      {
        path: 'organizations/marketplace_provider',
        element: <MarketplaceProviders />,
      },
      {
        path: 'organizations/marketplace_provider/:id',
        element: <MarketplaceProvider />,
      },
    ],
  },
]);

export default router;
