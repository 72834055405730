interface ColorExampleProps {
  hex?: string;
}

const ColorExample = ({ hex }: ColorExampleProps) => {
  return (
    <div
      className="relative w-10 h-10 mb-1 border border-gray-300"
      style={{ backgroundColor: hex || 'transparent' }}
    >
      {!hex && (
        <div className="absolute inset-x-0 top-1/2 border-t border-gray-600"></div>
      )}
    </div>
  );
};

export default ColorExample;
