import { sdk } from '@/api.ts';
import { useNavigate, useParams } from 'react-router';
import { SyntheticEvent, useEffect, useState } from 'react';
import { columns } from '@/routes/app/users/columns.tsx';
import { columns as inviteColumns } from '@/routes/app/organizations/shared/invites/columns.tsx';
import InviteForm from '@/routes/app/organizations/shared/invites/invite-form.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { Card } from '@/components/ui/card.tsx';
import { TOrganization } from '@/routes/app/organizations/columns.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { BiChevronRight } from 'react-icons/bi';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';

const OrganizationUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [organization, setOrganization] = useState<TOrganization | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });

  useEffect(() => {
    fetchUsers(pagination.page);
  }, [pagination.page]);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, page }));
    }
  };

  const fetchUsers = (page: number = 1) => {
    return sdk
      .getOrganizationUsers(id!, {
        params: {
          page,
          search: searchQuery,
        },
      })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchInvites = () => {
    return sdk
      .getInvites(id!)
      .then((response) => {
        setInvites(response.data.invites);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOrganization = () => {
    return sdk
      .getOrganization(id!)
      .then((response) => {
        setOrganization(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUsers();
    fetchInvites();
    fetchOrganization();
    setLoading(false);
  }, []);

  if (loading || !organization) {
    return <Card>Loading...</Card>;
  }
  return (
    <div>
      <Dialog open={showInviteModal} onOpenChange={setShowInviteModal}>
        <DialogContent>
          <DialogTitle>Invite new user</DialogTitle>
          <DialogDescription>
            Add participants and admins to your organization.
          </DialogDescription>
          <InviteForm
            onSuccess={fetchInvites}
            organizationId={organization!.id}
          />
        </DialogContent>
      </Dialog>
      <Tabs defaultValue="users">
        <TabsList>
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="invites">Invites</TabsTrigger>
        </TabsList>
        <TabsContent value="users">
          <Card>
            <div className="flex justify-end mb-4">
              <Button onClick={() => setShowInviteModal(true)}>
                Invite new users
              </Button>
            </div>

            <div className="flex justify-between items-center">
              <h1 className="font-bold text-2xl">
                Users for {organization?.name}
              </h1>
              <div className="flex justify-end">
                <form
                  className="flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    fetchUsers();
                  }}
                >
                  <Input
                    placeholder="Search by email"
                    onChange={(e: SyntheticEvent) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <Button type="submit" variant="secondary" className="ml-2">
                    <BiChevronRight size={22} />
                  </Button>
                </form>
              </div>
            </div>
            <Separator className="my-4" />
            <DataTable
              onPageChange={handlePageChange}
              data={users}
              columns={columns}
              pagination={pagination}
              navigationCallback={(data) => {
                return navigate(`/app/users/${data.id}`);
              }}
            />
          </Card>
        </TabsContent>
        <TabsContent value="invites">
          <Card>
            <div className="flex justify-end mb-4">
              <Button onClick={() => setShowInviteModal(true)}>
                Invite new users
              </Button>
            </div>
            <h1 className="font-bold text-2xl">
              Invites for {organization?.name}
            </h1>
            <Separator className="my-4" />
            <DataTable data={invites} columns={inviteColumns} />
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default OrganizationUsers;
