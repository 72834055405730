import { ColumnDef } from '@tanstack/react-table';
import { format, isBefore, parseISO } from 'date-fns';
import ModifyMenu from '@/routes/app/organizations/sponsors/single/modify-menu.tsx';
import { ICollateralItem } from '@/types.ts';

export const columns: ColumnDef<ICollateralItem>[] = [
  {
    accessorKey: 'artifact_name',
    header: 'File Name',
  },
  {
    accessorKey: 'created_at',
    header: 'Date Created',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: 'status_active',
    header: 'Status',
    cell: ({ row }) => {
      const status: boolean = row.getValue('status_active');
      return status ? 'Active' : 'Inactive';
    },
  },
  {
    accessorKey: 'participant_downloadable',
    header: 'Citation Shown',
    cell: ({ row }) => {
      const citationShown: boolean = row.getValue('participant_downloadable');
      return citationShown ? 'Yes' : 'No';
    },
  },
  {
    accessorKey: 'expires_at',
    header: 'Expires At',
    cell:({ row }) => {
      const expiresAt: string = row.getValue('expires_at');

      if (!expiresAt) {
        return 'No expiry set';
      }

      // Create date objects
      const expirationDate = parseISO(expiresAt);
      const now = new Date();

      // Adjust for timezone offset
      const utcDate = new Date(
        expirationDate.getTime() + expirationDate.getTimezoneOffset() * 60 * 1000
      );

      const expired = isBefore(utcDate, now);

      const formatDate = (date: Date) => {
        return new Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          timeZone: 'UTC'
        }).format(date);
      };

      if (expired) {
        return <span className="text-[red] font-bold">{formatDate(utcDate)}</span>;
      }
      return formatDate(utcDate);
    }
  },
  {
    accessorKey: '',
    header: 'Modify',
    cell: ({ cell }) => {
      return <ModifyMenu data={cell.row.original} />;
    },
  },
];
