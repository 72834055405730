import logo from '@/assets/images/logo/primary-orange.svg';
import { Button } from '@/components/ui/button.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { Link, useNavigate } from 'react-router';
import { SyntheticEvent } from 'react';

export default function Header() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const doLogout = async (e: SyntheticEvent) => {
    e.preventDefault();
    await logout();
    navigate('/');
  };

  return (
    <header className="border-outline sticky top-0 border border-b-2 bg-white py-2 pr-4 z-10">
      <div className="max-w-screen-lg mx-auto flex justify-between">
        <div className="flex-1 pl-4">
          <Link to="/">
            <img src={logo} className="max-w-[133px]" alt="Logo" />
          </Link>
        </div>
        <Button onClick={doLogout}>Sign Out</Button>
      </div>
    </header>
  );
}
