import { Button } from '@/components/ui/button.tsx';
import { Link } from 'react-router';

interface ThemePreviewerProps {
  theme: {
    colors_primary?: string;
    'colors_app-background'?: string;
    colors_linear1?: string;
    colors_linear2?: string;
    colors_linear3?: string;
    logo?: string;
    copy_disclaimer?: string;
  };
}

const ThemePreviewer = ({ theme }: ThemePreviewerProps) => {
  return (
    <div
      className="border min-w-[300px] rounded-lg h-[600px]"
      style={{ backgroundColor: theme['colors_app-background'] }}
    >
      <div className="flex flex-col gap-y-4 h-full pb-4">
        <header className="border-outline sticky top-0 border border-b-2 bg-white py-2">
          <div className="max-w-screen-lg mx-auto flex justify-between">
            <div className="flex-1 pl-4">
              <Link to="/">
                <img src={theme.logo} className="max-w-[133px]" alt="Logo" />
              </Link>
            </div>
          </div>
        </header>
        <div className="p-4">
          <Button style={{ backgroundColor: theme.colors_primary }}>
            Primary
          </Button>
        </div>
        <div className="flex-grow"></div>
        <div className="text-sm">
          <div className="flex justify-center gap-x-2.5 mb-2">
            <span className="text-primary underline">Terms of Service</span> |
            <span className="text-primary underline">Privacy Policy</span>
          </div>
          <p className="text-center">{theme.copy_disclaimer}</p>
        </div>
      </div>
    </div>
  );
};

export default ThemePreviewer;
