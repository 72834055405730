import { Card } from '@/components/ui/card.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import ThemeForm from '@/routes/app/organizations/sponsors/single/theme/theme-form.tsx';
import { useEffect, useState } from 'react';
import { ITheme } from '@/types.ts';
import { sdk } from '@/api.ts';

const Theme = ({ organizationId }: { organizationId: string }) => {
  const [theme, setTheme] = useState<ITheme | null>(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const response = await sdk.getTheme(organizationId);
      console.log('response: ', response);
      setTheme(response);
    } catch (error) {
      // @TODO how to error?
    }
  };

  const onSuccess = () => {
    init();
  };

  return (
    <Card>
      <h3 className="font-semibold text-xl">Theme</h3>
      <Separator className="my-2" />
      <ThemeForm
        organizationId={organizationId}
        onSuccess={onSuccess}
        theme={theme}
      />
    </Card>
  );
};

export default Theme;
