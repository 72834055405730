import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { statesList } from '@/utils.ts';
import { ReactNode, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IOrganization, TOrganizationType } from '@/types.ts';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router';
import { Button } from '@/components/ui/button.tsx';
import { FormTextField, TextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  name: z.string().min(2, { message: 'Name is required' }).max(50),
  business_address: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_line_two: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  business_address_city: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_state: z.string().optional().or(z.literal('')),
  business_address_postal_code: z
    .string()
    .regex(/^\d{5}$/)
    .optional()
    .or(z.literal('')),
  business_tax_id: z
    .string()
    .regex(/^\d{2}-\d{7}$/)
    .optional()
    .or(z.literal('')),
  primary_contact_full_name: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  primary_contact_email: z.string().email().optional().or(z.literal('')),
  primary_contact_phone_number: z
    .string()
    .regex(/^\d{3}-\d{3}-\d{4}$/, { message: 'Invalid phone number format' })
    .optional()
    .or(z.literal('')),
});

const formDefaults = {
  name: '',
  business_address: '',
  business_address_line_two: '',
  business_address_city: '',
  business_address_state: '',
  business_address_postal_code: '',
  business_tax_id: '',
  primary_contact_full_name: '',
  primary_contact_email: '',
  primary_contact_phone_number: '',
};
export default function OrganizationForm({
  onClickCancel,
  organization,
  orgType,
}: {
  onClickCancel: () => void;
  organization?: IOrganization;
  orgType?: TOrganizationType;
}) {
  const [statusMessage, setStatusMessage] = useState<ReactNode>('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: organization
      ? {
          ...formDefaults,
          ...Object.fromEntries(
            // replacing null values with empty strings
            Object.entries(organization).map(([key, value]) => [
              key,
              value === null ? '' : value,
            ])
          ),
        }
      : formDefaults,
  });

  // Using an effect here as a watcher to manage the button / message states
  useEffect(() => {
    if (loading) {
      setStatusMessage('');
      return;
    }
    if (success) {
      return;
    }
    if (error) {
      setStatusMessage('Error creating organization.');
      return;
    }

    setStatusMessage('');
  }, [loading, success, error]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    let organizationID;

    // If an organization has been provided as a prop, we are updating an
    // existing organization, otherwise we are creating a new organization
    if (organization) {
      organizationID = organization.id;
      sdk
        .updateOrganization(organizationID, {
          ...data,
        })
        .then(() => {
          setSuccess(true);
          setStatusMessage('Organization updated successfully');
          setError(false);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setSuccess(false);
          setLoading(false);
        });
    } else {
      sdk
        .createOrganization({
          ...data,
          business_type: orgType,
        })
        .then((res) => {
          setSuccess(true);
          setStatusMessage(
            res.id ? (
              <Link
                className="flex underline items-center text-blue-600"
                to={`/app/organizations/${orgType}/${res.id}`}
              >
                View new organization
                <BiRightArrowAlt className="ml-2" />
              </Link>
            ) : (
              'Organization created successfully'
            )
          );
          setError(false);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setSuccess(false);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <h1 className="text-xl font-semibold mb-4">Org Information</h1>
          <div className="flex gap-x-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Company Name"
                      required
                      error={form.formState.errors.name?.message}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="business_tax_id"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="EIN"
                      error={form.formState.errors.business_tax_id?.message}
                      helperText="12-3456789"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="flex gap-x-4">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="business_address"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Address Line 1"
                        error={form.formState.errors.business_address?.message}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="business_address_line_two"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Address Line 2"
                        error={
                          form.formState.errors.business_address_line_two
                            ?.message
                        }
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex gap-x-4">
            <FormField
              control={form.control}
              name="business_address_city"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="City"
                      error={
                        form.formState.errors.business_address_city?.message
                      }
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex-1">
              <FormField
                control={form.control}
                name="business_address_state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger test-id="state-dropdown">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <FormMessage />
                      <SelectContent>
                        {statesList.map((state) => (
                          <SelectItem
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="business_address_postal_code"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Zip/Postal Code"
                      error={
                        form.formState.errors.business_address_postal_code
                          ?.message
                      }
                      helperText="ex: 00000"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <h1 className="text-xl font-semibold my-4">
            Primary Contact Information
          </h1>
          <FormField
            control={form.control}
            name="primary_contact_full_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormTextField
                    label="Full Name"
                    error={
                      form.formState.errors.primary_contact_full_name?.message
                    }
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex gap-x-4">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="primary_contact_phone_number"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Phone Number"
                        error={
                          form.formState.errors.primary_contact_phone_number
                            ?.message
                        }
                        helperText="123-456-7890"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="primary_contact_email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TextField label="Email Address" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex gap-x-2 mt-4 items-center">
            <div className="flex gap-x-2">
              <Button loading={loading}>Save</Button>
              <Button variant="outline" onClick={onClickCancel}>
                Cancel
              </Button>
            </div>
            <div>{statusMessage}</div>
          </div>
        </form>
      </Form>
    </div>
  );
}
