import Header from '@/components/header.tsx';
import { Outlet } from 'react-router';

function AppLayout() {
  return (
    <div className="bg-app-background h-full overflow-auto">
      <Header />
      <div className="max-w-screen-lg mx-auto">
        <main className="p-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default AppLayout;
