import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { FormTextField } from '@/components/text-field.tsx';
import ColorExample from '@/routes/app/organizations/sponsors/single/theme/color-example.tsx';
import ThemePreviewer from '@/routes/app/organizations/sponsors/single/theme/theme-previewer.tsx';
import { ITheme } from '@/types.ts';
import { useEffect, useState } from 'react';
import { Separator } from '@/components/ui/separator.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { Button } from '@/components/ui/button.tsx';
import { sdk } from '@/api.ts';

const formSchema = z.object({
  logo: z.any().refine((file) => file.length > 0, 'Logo is required'),
  colors_primary: z.string().optional(),
  'colors_app-background': z.string().optional(),
  colors_linear1: z.string().optional(),
  colors_linear2: z.string().optional(),
  colors_linear3: z.string().optional(),
  copy_disclaimer: z.string().optional(),
});

const ThemeForm = ({
  theme,
  onSuccess,
  organizationId,
}: {
  organizationId: string;
  onSuccess: () => void;
  theme: ITheme | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const isNewTheme = !theme || Object.keys(theme).length === 0;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      colors_primary: '',
      'colors_app-background': '',
      colors_linear1: '',
      colors_linear2: '',
      colors_linear3: '',
      copy_disclaimer: '',
    },
  });

  // Use useEffect to set form values when theme changes
  useEffect(() => {
    if (theme && Object.keys(theme).length > 0) {
      form.reset({
        logo: theme.images.logo || '',
        colors_primary: theme.colors.primary || '',
        'colors_app-background': theme.colors['app-background'] || '',
        colors_linear1: theme.colors.linear1 || '',
        colors_linear2: theme.colors.linear2 || '',
        colors_linear3: theme.colors.linear3 || '',
        copy_disclaimer: theme.copy.disclaimer || '',
      });
    }
  }, [theme, form]);

  const allFormValues = form.watch();
  const fileRef = form.register('logo');

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('logo', data.logo[0]);
    formData.append('primary', data.colors_primary as string | Blob);
    formData.append('app-background', data['colors_app-background'] as string);
    formData.append('linear1', data.colors_linear1 as string);
    formData.append('linear2', data.colors_linear2 as string);
    formData.append('linear3', data.colors_linear3 as string);
    formData.append('disclaimer', data.copy_disclaimer as string);
    formData.append('organization_id', organizationId as string);

    try {
      // If theme exists, update it, otherwise create a new one
      if (isNewTheme) {
        await sdk.createTheme(formData);
      } else {
        await sdk.updateTheme(formData);
      }

      setSuccess(true);
      setError(false);
      setLoading(false);
      onSuccess();
    } catch (error) {
      setError(true);
      setSuccess(false);
      setLoading(false);
      // @TODO handle error
    }
    console.log(data);
  };

  return (
    <div className="flex justify-between gap-x-16">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="space-y-2 w-full"
        >
          {/* Images */}
          <section>
            <h4 className="bg-accent p-2 w-full font-bold">Images</h4>
            <Separator className="mb-2" />
            <div className="flex gap-x-2 justify-between items-center">
              <FormField
                name="logo"
                control={form.control}
                render={() => (
                  <FormItem>
                    <FormLabel>Logo</FormLabel>
                    <FormControl>
                      <FormTextField
                        type="file"
                        {...fileRef}
                        error={form.formState.errors.logo?.message}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <img src={form.watch('logo')} className="max-w-[133px]" />
            </div>
          </section>

          {/* Colors */}
          <section>
            <h4 className="bg-accent p-2 w-full font-bold mt-4">Colors</h4>
            <Separator className="mb-2" />
            <div className="flex gap-x-2 items-end">
              <FormField
                control={form.control}
                name="colors_primary"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        test-id="colors-primary"
                        {...field}
                        label="Primary"
                        error={form.formState.errors.colors_primary?.message}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <ColorExample hex={form.watch('colors_primary')} />
            </div>

            <div className="flex gap-x-2 items-end">
              <FormField
                control={form.control}
                name="colors_app-background"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        test-id="colors-app-background"
                        {...field}
                        label="App Background"
                        error={
                          form.formState.errors['colors_app-background']
                            ?.message
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <ColorExample hex={form.watch('colors_app-background')} />
            </div>

            <div className="flex gap-x-2 items-end">
              <FormField
                control={form.control}
                name="colors_linear1"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        test-id="colors-linear1"
                        {...field}
                        label="Linear 1 (Gradient Step 1)"
                        error={form.formState.errors.colors_linear1?.message}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <ColorExample hex={form.watch('colors_linear1')} />
            </div>

            <div className="flex gap-x-2 items-end">
              <FormField
                control={form.control}
                name="colors_linear2"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        test-id="colors-linear2"
                        {...field}
                        label="Linear 2 (Gradient Step 2)"
                        error={form.formState.errors.colors_linear2?.message}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <ColorExample hex={form.watch('colors_linear2')} />
            </div>

            <div className="flex gap-x-2 items-end">
              <FormField
                control={form.control}
                name="colors_linear3"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        test-id="colors-linear3"
                        {...field}
                        label="Linear 3 (Gradient Step 3)"
                        error={form.formState.errors.colors_linear3?.message}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <ColorExample hex={form.watch('colors_linear3')} />
            </div>
          </section>

          <section>
            <h4 className="bg-accent p-2 w-full font-bold mt-4">Copy</h4>
            <Separator className="mb-2" />
            <div className="flex">
              <FormField
                control={form.control}
                name="copy_disclaimer"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Disclaimer Copy</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormDescription>
                      This is the copy shown below the chat interface.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </section>

          <Button loading={loading} type="submit">
            Save
          </Button>
          {success ? (
            <p className="text-success">Theme added successfully</p>
          ) : null}
          {error ? (
            <p className="text-error">Error adding theme data.</p>
          ) : null}
        </form>
      </Form>
      <ThemePreviewer theme={allFormValues} />
    </div>
  );
};

export default ThemeForm;
