import {
  Form,
  FormControl,
  FormField,
  FormItem, FormLabel,
} from '@/components/ui/form.tsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ICollateralItem } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import { sdk } from '@/api.ts';
import { FormTextField } from '@/components/text-field.tsx';
import useDocumentsStore from '@/routes/app/organizations/sponsors/single/documents/store.ts';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Separator } from '@/components/ui/separator.tsx';

const formSchema = z.object({
  artifact_name: z.string(),
  description: z.string().optional().or(z.literal('')),
  expires_at: z.string().optional().or(z.literal('')),
  participant_downloadable: z.boolean().default(true).optional()
});

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    timeZone: 'UTC'
  }).format(date);
};

const EditCollateralForm = ({
  collateral,
}: {
  collateral: ICollateralItem;
}) => {
  const { fetchDocuments } = useDocumentsStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      artifact_name: collateral.artifact_name,
      description: collateral.description || '',
      expires_at: collateral.expires_at ? formatDate(new Date(collateral.expires_at)) : '',
      participant_downloadable: collateral.participant_downloadable,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    const { artifact_name, description, expires_at, participant_downloadable } = data;
    const formData = new FormData();
    formData.append('artifact_name', artifact_name);
    formData.append('participant_downloadable', participant_downloadable as any);
    if(description) {
      formData.append('description', description);
    }
    if(expires_at) {
      formData.append('expires_at', expires_at);
    }
    sdk
      .patchOrganizationDocument(
        collateral.organization_id,
        collateral.id,
        formData
      )
      .then(() => {
        setSuccess(true);
        setLoading(false);
        fetchDocuments(collateral.organization_id);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="artifact_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField label="Name" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField label="Description" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="expires_at"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Expires at (optional)"
                  helperText={"YYYY-MM-DD or MM/DD/YYYY"}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="participant_downloadable"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>
                  Show Citations
                </FormLabel>
              </div>
            </FormItem>
          )}
        />
        <Separator />
        <div className="flex gap-x-4 items-center">
          <Button loading={loading} type="submit">
            Save
          </Button>
          <p>
            {error && 'Error updating document. Please try again.'}
            {success && 'Document updated successfully.'}
          </p>
        </div>
      </form>
    </Form>
  );
};

export default EditCollateralForm;
