import { Link } from 'react-router';
import { Card, CardContent, CardTitle } from '@/components/ui/card.tsx';
import { BiChevronRight } from 'react-icons/bi';
import { useAuth } from '@/providers/auth.tsx';

function App() {
  const { user } = useAuth();

  if (user.role !== 'superuser') {
    return (
      <Card className="border border-error">
        <CardTitle>Whoa there, partner! ✋🤠</CardTitle>
        <CardContent className="flex flex-col gap-y-4">
          <p className="mt-4">
            The email you're logged in with ({user.email})&nbsp;
            <span className="italic font-semibold">is not a superuser.</span>
          </p>
          <p>
            Log out and log back in with the right permissions, or request a
            developer to upgrade your email to the appropriate role.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="flex gap-4">
      <Link to="/app/organizations">
        <Card>
          <CardTitle className="flex justify-between items-center">
            <span>Organizations</span>
            <BiChevronRight />
          </CardTitle>
          <CardContent className="pt-2">
            Create and manage organizations
          </CardContent>
        </Card>
      </Link>
    </div>
  );
}

export default App;
