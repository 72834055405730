import { format } from 'date-fns';
import { IFinchConnection } from '@/types.ts';

interface FinchConnectionDataProps {
  connection: IFinchConnection | Record<string, never>;
}

const FinchConnectionData = ({ connection }: FinchConnectionDataProps) => {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Finch Connection Data</h2>
      {Object.keys(connection).length ? (
        <div>
          <dl className="detail-dl">
            <dt>Finch Provider ID</dt>
            <dd>{connection.finch_provider_id}</dd>

            <dt>Created at</dt>
            <dd>{connection.created_at}</dd>

            <dt>Connection ID</dt>
            <dd>{connection.connection_id}</dd>

            <dt>Status Active</dt>
            <dd>{connection.status_active.toString()}</dd>

            <dt>Cron Active</dt>
            <dd>{connection.cron_active.toString()}</dd>

            <dt>Data Refreshed At</dt>
            <dd>
              {connection.data_refreshed_at
                ? format(new Date(connection.data_refreshed_at), 'MM/dd/yyyy')
                : 'N/A'}
            </dd>
          </dl>
        </div>
      ) : (
        <p>No connection data.</p>
      )}
    </div>
  );
};

export default FinchConnectionData;
